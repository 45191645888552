<template>
  <div class="event-single" :class="{ 'current-event': isCheckedIn && currentEvent == uuid }">
    <div v-if="loading" class="flex flex-col items-center space-y-4 text-center">
      <icon-loading class="w-10 h-10 text-brand-lightblue animate-spin" />
      <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
    </div>
    <div class="flex flex-col min-h-full" v-else-if="event && event.uuid">
      <router-view :event="event" :auto-checkin="autoCheckin" :auto-open-form="autoOpenForm" @event-updated="updateEvent" />
    </div>
    <p class="p-4 text-sm italic" v-else>{{ $t("event.not-found") }}</p>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import IconLoading from "assets/icons/loading.svg";
import { USER_REQUEST } from "actions/user";
import { mapGetters } from "vuex";

const EventRepository = Repository.get("my-events");

export default {
  name: "event-wrapper",
  components: {
    IconLoading,
  },
  props: {
    uuid: String,
    createEventSilentlyAtLoad: {
      type: Boolean,
      default: false,
    },
    autoCheckin: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    autoOpenForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    checkedIn: false,
    loading: true,
    event: null,
    showCheckinModal: false,
    checkInMood: "neutral",
    comment: "...",
  }),
  methods: {
    getEvent: function () {
      this.loading = true;
      EventRepository.get(this.uuid)
        .then((r) => (this.event = r.data.event))
        .catch(() => {})
        .then(() => (this.loading = false));
    },
    /* grabevent(uuid) {
      this.loading = true;
      api.get('/api/my/events/'+uuid)
        .then(r => { this.updateEvent(r.data) })
        .then(() => { this.loading = false; });
    }, */
    changeStatus(state) {
      this.checkedIn = state;
    },
    handleClosingModal(state) {
      // console.log('handleClosingModal', state)
      this.checkedIn = state !== undefined ? state : false;
      this.showCheckinModal = false;
    },
    updateEvent(data) {
      this.loading = true;
      if (!data) {
        this.getEvent();
      } else {
        this.event = data;
      }
      this.loading = false;
    },
    /* handleCheckinUpdated(data) {
        this.updateEvent(data)
        // also, check if the user has this event as the active check-in:
        this.$store.dispatch(USER_REQUEST);
    }, */
    handleCheckinUpdated() {
      this.$store.dispatch(USER_REQUEST); // load currentEvent stuff
    },
  },
  computed: {
    ...mapGetters(["isCheckedIn", "currentEvent"]),
  },
  mounted: function () {
    if (!this.uuid) {
      return;
    }
    this.getEvent();
  },
  metaInfo() {
    return {
      title: `${this.event ? this.event.name : "Loading..."} - ${this.$i18n.t("event.event")}`,
      meta: [
        {
          name: "theme-color",
          content: this.isCheckedIn ? "#0000ff" : "#00ff00",
        },
      ],
    };
  },
};
</script>
